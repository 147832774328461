<template>
  <div class="personal-change">
    <div class="top">
      <div class="logo">
        <img src="../../assets/logo.png" alt="" />
      </div>
      <p>修改密码</p>
    </div>
    <div class="main">
      <div class="form">
        <input type="password" style="display: none" />
        <yl-input
          class="old"
          v-model="formData.oldCode"
          type="password"
          :forget="true"
          placeholder="请输入您的当前密码"
          ><img src="../../assets/password.png" alt=""
        /></yl-input>
        <yl-input
          v-model="formData.password"
          type="password"
          placeholder="请输入您的新密码"
          ><img src="../../assets/password.png" alt=""
        /></yl-input>
        <yl-input
          v-model="formData.alignPassword"
          type="password"
          placeholder="请再次输入您的新密码"
          ><img src="../../assets/password.png" alt=""
        /></yl-input>
      </div>
      <button class="comfirm" @click="submitFn">确认修改</button>
    </div>
  </div>
</template>

<script>
import YlInput from "../../components/yl-input.vue";
import { updateUserPassword, havePassword, wechatConfig } from "../../api/user";
import wechat from "../../utils/wxShare";
export default {
  components: {
    YlInput,
  },
  metaInfo: {
    title: "修改密码",
  },
  data() {
    return {
      formData: {
        oldCode: "",
        password: "",
        alignPassword: "",
      },
      phoneReg: /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/,
      passwordReg: /^[a-zA-Z0-9]{6,12}$/,
      emailReg:
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
    };
  },
  created() {
    this.getHavePassword();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    getHavePassword() {
      havePassword().then((res) => {
        if (res.code == 1244) {
          this.$router.replace({ path: "/retrieve" });
        } else if (res.code == 60000) {
          this.$router.replace({ path: "/login" });
        }
      });
    },
    submitFn() {
      for (var key in this.formData) {
        if (!this.formData[key]) {
          this.$ylmessage.error("请输入您的旧密码与新密码");
          return;
        }
      }
      // else {
      // if (this.formData.password && this.formData.alignPassword) {
      if (this.formData.password != this.formData.alignPassword) {
        this.$ylmessage.error("两次密码不一致");
      } else if (
        !this.passwordReg.test(this.formData.oldCode) ||
        !this.passwordReg.test(this.formData.password) ||
        !this.passwordReg.test(this.formData.alignPassword)
      ) {
        this.$ylmessage.error("密码由6~12位字母数字组合");
        return;
      } else {
        // console.log("修改密码");
        updateUserPassword({
          id: this.formData.id,
          oldCode: this.formData.oldCode,
          password: this.formData.password,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("密码修改成功");
            this.formData.oldCode = "";
            this.formData.password = "";
            this.formData.alignPassword = "";
            setTimeout(() => {
              this.$router.replace({ path: "/personal" });
            }, 1500);
          } else {
            this.$ylmessage.error(res.msg);
          }
        });
      }
      // }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-change {
  height: 100%;
  background: url("../../assets/h5bg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .top {
    height: 1.35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 1.85rem;
      img {
        width: 100%;
        display: block;
      }
    }
    p {
      margin: 0;
      color: #fff;
      font-size: 0.16rem;
      font-weight: bold;
      margin-top: 0.2rem;
    }
  }
  .main {
    padding: 0 0.25rem;
    .form {
      padding: 0.35rem 0.15rem;
      background: #fff;
      border-radius: 0.08rem;
      .old {
        margin-bottom: 0.3rem;
      }
    }
    .comfirm {
      height: 0.4rem;
      margin-top: 0.4rem;
      color: #fff;
      background: #1872bb;
      border-radius: 0.2rem;
      border: none;
      width: 100%;
      font-size: 0.16rem;
    }
  }
}
</style>
